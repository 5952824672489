/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { setProfile } from '../state/profile/profile';
import { setAnonymousRecentlyViewedProducts } from '../state/anonymousRecentlyViewedProducts/anonymousRecentlyViewedProducts';
import { getValidResponseBody, userApi } from '../api';
import { getPopupOnPagePath } from './appRoutes';
import { isServer } from './isServer';
import { parseValidJSON } from './parseValidJSON';
import { useUserProfile } from './useUserProfile';
import { setAnonymousLikedProducts } from '../state/anonymousLikedProducts/anonymousLikedProducts';
import useNotifications from '../components/NotificationPopup/useNotifications';
import { ARD_HOME, ARD_POPUP_LOGIN } from './appRoutes.definitions';
import { isMPGTMEnabled } from './isGTMEnabled';
import { migrateProfileWishlist } from './wishlistMigration';
import useAdultContentHandlers from './useAdultContentHandlers';

//Signup testing / registration testing
if (!isServer) {
  window.newUserImitation = () => {
    localStorage.setItem('newUserImitation', true);
  };
}

//todo: request a new token when expired
//todo: apply useDefferedFetch for all requests dependent on JWT

//TODO: REFACTOR ENTIRE AUTH PROCESS WITH DATA HANDLING
//1. DONE: Provide JWT separately from user profile etc. and get rid of all profile?.token occurances
//2. Update auth0 library if needed
//3. Provide JWT validation on client side
//4. Handle token renewal
//5. useDefferedFetch for >ALL< JWT dependant api calls
const redirectCookieName = 'postAuthRedirect';
export const Auth0Context = React.createContext();
export const useAuth0 = () => {
  const context = useContext(Auth0Context);
  return context || {};
};

const mapDispatchToProps = (dispatch) => ({
  setProfile: (profile) => {
    dispatch(setProfile(profile));
  },
  setRecentlyViewProductsAnonymous: (recentlyViewedProducts) => {
    dispatch(setAnonymousRecentlyViewedProducts(recentlyViewedProducts));
  },
  setAnonymousWishlist: (wishlist) => {
    dispatch(setAnonymousLikedProducts(wishlist));
  },
});

const mapStateToProps = ({ config, profile, anonymousRecentlyViewedProducts, anonymousLikedProducts }) => ({
  config,
  profile,
  anonymousRecentlyViewedProducts,
  anonymousLikedProducts,
});
export const Auth0Provider = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  ({
    children,
    redirect_uri,
    client_id,
    domain,
    setProfile: setReduxProfile,
    profile: reduxProfile,
    anonymousRecentlyViewedProducts,
    setRecentlyViewProductsAnonymous,
    setAnonymousWishlist,
    anonymousLikedProducts,
  }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [auth0User, setAuth0User] = useState(null);
    const [auth0Client, setAuth0] = useState(null);
    const [tokenClaims, setTokenClaims] = useState(null);
    const { push } = useHistory();
    const { pathname, search } = useLocation();
    const [cookies, setCookie, removeCookie] = useCookies([redirectCookieName]);
    const [loginInitiated, setLoginInitiated] = useState(false);
    //while signupRequired is set to true, signup process (agreement and signup request) is in progress
    const [signupRequired, setSignupRequired] = useState(false);
    const { getUserData, setUserData } = useUserProfile(tokenClaims?.__raw);
    const { sendNotification } = useNotifications();
    const setSignupAttemptCompleted = () => setSignupRequired(false);
    const dispatch = useDispatch();
    const isReduxProfileSyncedWithApiRef = useRef(false);
    const localStorageUserProfile = !isServer && localStorage.getItem('reduxProfile');
    const userAlreadyLoggedIn = !!localStorageUserProfile;
    const { unblockAdultContent, forbidAdultContent } = useAdultContentHandlers();

    const loginWithRedirect = useCallback(
      (...p) => {
        if (isMPGTMEnabled) window.dataLayer.push({ event: 'login_attempt' });
        setCookie(redirectCookieName, { pathname, search }, { path: '/' });
        auth0Client.loginWithRedirect(...p);
      },
      [auth0Client, pathname, search, setCookie]
    );

    const logoutWithCleanSession = (logoutFn) => {
      localStorage.removeItem('reduxProfile');
      localStorage.removeItem('tokenClaims');
      setReduxProfile(null);
      setTokenClaims(null);
      //content should be blocked only for underage users
      unblockAdultContent();
      //but still should be hidden by default
      forbidAdultContent();
      if (isMPGTMEnabled) window.dataLayer.push({ event: 'logout_attempt' });
      return logoutFn();
    };

    const logoutExpiredSession = useCallback(
      (notificationText) => {
        const redirectToLogin = () => {
          push(getPopupOnPagePath(ARD_POPUP_LOGIN, ARD_HOME));
          location.reload();
        };

        if (notificationText) {
          sendNotification(notificationText);
        }

        if (auth0Client?.logout)
          logoutWithCleanSession(() =>
            auth0Client?.logout({ returnTo: `${process.env.REACT_APP_BASE_URL}/auth/logout` })
          );
        else logoutWithCleanSession(redirectToLogin);
      },
      [auth0Client, push, sendNotification]
    );

    const updateBasicDataFromAuth0 = useCallback(
      (profile) => {
        const updatedFields = ['email', 'family_name', 'given_name', 'picture'].reduce((acc, field) => {
          if (auth0User[field] === profile[field]) return acc;
          else return { ...acc, [field]: auth0User[field] };
        }, {});

        if (!Object.keys(updatedFields)?.length) return null;
        return {
          ...profile,
          ...updatedFields,
        };
      },
      [auth0User]
    );

    const updatePUGChats = (profile) => {
      const anonymousChats = JSON.parse(localStorage?.getItem('pocChatIds') || '[]');
      if (!anonymousChats.length) return null;

      const pocChatData = profile?.pocChatData || [];
      localStorage.removeItem('pocChatIds');
      localStorage.removeItem('pocChatId');
      return {
        ...profile,
        pocChatData: [...pocChatData, ...anonymousChats],
      };
    };

    const updateRVP = useCallback(
      (profile) => {
        if (!anonymousRecentlyViewedProducts.length) return null;
        setRecentlyViewProductsAnonymous([]);
        localStorage.removeItem('rVP');
        return {
          ...profile,
          recentlyViewedProducts: [...profile.recentlyViewedProducts, ...anonymousRecentlyViewedProducts],
        };
      },
      [anonymousRecentlyViewedProducts, setRecentlyViewProductsAnonymous]
    );

    const mutateUserProfile = useCallback(
      (profile) => {
        const operations = [migrateProfileWishlist, updateBasicDataFromAuth0, updateRVP, updatePUGChats];
        const updates = operations.reduce(
          (acc, operation) => {
            const processedProfile = operation(acc.profile);
            if (!processedProfile) return acc;
            return { present: true, profile: processedProfile };
          },
          { present: false, profile }
        );

        if (updates.present) return updates.profile;
        else return null;
      },
      [updateBasicDataFromAuth0, updateRVP]
    );

    //initialize auth0 client
    const initAuth0 = useCallback(async () => {
      if (auth0Client) return;
      const Auth0SPA = await import('@auth0/auth0-spa-js');
      const createAuth0Client = Auth0SPA.default;
      const auth0FromHook = await createAuth0Client({
        redirect_uri,
        client_id,
        domain,
      }).catch((reason) => {
        console.error('Error initialising Auth0 client:', reason);
      });
      setAuth0(auth0FromHook);
    }, [auth0Client, client_id, domain, redirect_uri]);

    const loadAuth0Profile = useCallback(async () => {
      //todo: do both calls should be executed?
      const [isCurrentlyAuthenticated, authUser] = await Promise.all([
        auth0Client.isAuthenticated(),
        auth0Client.getUser(),
      ]);

      setIsAuthenticated(!!isCurrentlyAuthenticated);

      if (isCurrentlyAuthenticated) setAuth0User(authUser);
      else {
        logoutExpiredSession('User session has been expired. Please login again');
      }
    }, [auth0Client, logoutExpiredSession]);

    /**
     * Transfers anonymous liked products to profile
     * @returns bool isTransferExecuted
     */
    const saveAnonymousWishlistToProfile = useCallback(
      async (anonymousLikedProducts) => {
        // save updated profile in user api
        if (!anonymousLikedProducts?.length) return false;
        //todo: use a separate endpoint to transfer anonymously liked products to user profile all at once
        await Promise.all(
          anonymousLikedProducts.map(({ productId }) =>
            userApi.addWishlistProduct({
              //Use endpoint exported from userApi directly
              body: JSON.stringify({ id: productId }),
              headers: { Authorization: `Bearer ${tokenClaims?.__raw}` },
              method: 'POST',
            })
          )
        );
        setAnonymousWishlist([]);
        localStorage.removeItem('wishlistItems');
        return true;
      },
      [setAnonymousWishlist, tokenClaims?.__raw]
    );

    const getProfileData = useCallback(async () => {
      const newUserImitation = localStorage.getItem('newUserImitation');
      const nuiRegistered = localStorage.getItem('nuiRegistered');
      if (newUserImitation && !nuiRegistered) {
        setSignupRequired(true);
        return;
      }

      const response = await getUserData();

      if (response.ok) {
        const responseProfile = getValidResponseBody(response);

        if (responseProfile === null && !signupRequired) {
          setSignupRequired(true);
          //user created in auth0, but not on our end
          //engage registration script
          return;
        }
        //signup script is in progress, profile not ready yet
        if (responseProfile === null && signupRequired) return;
        //signup completed successfully and profile is ready
        if (responseProfile && signupRequired) setSignupRequired(false);

        const mutatedUserProfile = mutateUserProfile(responseProfile);

        if (mutatedUserProfile) {
          //user profile should be updated remotely
          setUserData({ body: JSON.stringify(mutatedUserProfile) }).then(async () => {
            const anonymousWishlistTransfered = await saveAnonymousWishlistToProfile(anonymousLikedProducts);
            if (!anonymousWishlistTransfered) isReduxProfileSyncedWithApiRef.current = true;
          });
          return;
        } else {
          const anonymousWishlistTransfered = await saveAnonymousWishlistToProfile(anonymousLikedProducts);
          if (anonymousWishlistTransfered) return;
          //after saveAnonymousWishlistToProfile being invoked getProfileData should be triggered again from useEffect
          isReduxProfileSyncedWithApiRef.current = true;
          setReduxProfile(responseProfile); //remote profile is up to date
        }
      } else {
        console.error('Profile fetch failed');
        logoutExpiredSession('Login failed');
      }
      //todo: handle failed login attempt
    }, [
      anonymousLikedProducts,
      getUserData,
      logoutExpiredSession,
      mutateUserProfile,
      saveAnonymousWishlistToProfile,
      setReduxProfile,
      setUserData,
      signupRequired,
    ]);

    //todo: remove when expired session handling will be completed
    useEffect(() => {
      window.testEL = logoutExpiredSession;
    }, [logoutExpiredSession]);

    // initialise auth0
    useEffect(() => {
      // todo: create useReadyStateEffect to execute useEffect on window.onload
      //todo: load auth0 if there is a need for that, for examole, credentials are present
      //todo: this is bottleneck
      if (document.readyState === 'complete') initAuth0();
      else
        window.addEventListener('load', () => {
          initAuth0();
        });
    }, [initAuth0]);
    //step 1: redirect to initial url after successfull auth0 login
    useEffect(() => {
      (async () => {
        const redirectPage = pathname.includes('/auth-redirect');
        if (redirectPage && search.includes('error=')) {
          push('/');
          sendNotification('Login failed. Try again');
          return;
        }
        if (!redirectPage || !auth0Client || !search.includes('code=')) return;
        //auth0 redirect url is opened, handleRedirectCallback should be called
        await auth0Client.handleRedirectCallback().catch((reason) => {
          console.error('Error handling Auth0 redirect callback:', reason);
        });

        const redirectOptions = cookies[redirectCookieName];
        const redirectUrl = redirectOptions ? redirectOptions.pathname + redirectOptions.search : '/';
        removeCookie(redirectCookieName, { path: '/' });
        setLoginInitiated(true);
        push(redirectUrl);
      })();
    }, [auth0Client, cookies, push, pathname, removeCookie, search, sendNotification]);
    //step 2: load auth0 profile
    useEffect(() => {
      const redirectPage = pathname.includes('/auth-redirect');
      const authRequested = loginInitiated || userAlreadyLoggedIn;
      if (!authRequested) {
        setIsAuthenticated(false);
        return;
      }
      if (auth0Client && authRequested && !auth0User && !redirectPage) loadAuth0Profile();
    }, [auth0Client, auth0User, loadAuth0Profile, loginInitiated, pathname, userAlreadyLoggedIn]);
    //step 3: get token claims. mainly for user api
    useEffect(() => {
      //todo: token renewal
      //todo: remove tokenClaims and set isAuthenticated before token renewal
      if (auth0Client && isAuthenticated && !tokenClaims) {
        auth0Client
          .getIdTokenClaims()
          .then((claims) => {
            setTokenClaims((prevClaims) => {
              if (isMPGTMEnabled && !prevClaims) {
                //register login via GTM
                window.dataLayer.push({
                  event: 'login_success',
                  user_id: claims?.sub, //sub (subject): Subject of the JWT (the user)
                });
              }
              return claims;
            });
            localStorage.setItem('tokenClaims', JSON.stringify(claims));
          })
          .catch((reason) => {
            console.warn('Claiming token failed:', reason);
            setTokenClaims(null);
            localStorage.removeItem('tokenClaims');
            setReduxProfile(null);
            localStorage.removeItem('reduxProfile');
          });
      }
    }, [auth0Client, isAuthenticated, setReduxProfile, tokenClaims]);

    //step 4: get profile data
    useEffect(() => {
      if (!reduxProfile) {
        //Load previously authorized user profile to provide user data at page load

        //const localStorageTokenClaimsString = localStorage.getItem('tokenClaims');
        const localStorageReduxProfileString = localStorage.getItem('reduxProfile');

        //const localStorageTokenClaims = localStorageTokenClaimsString && parseValidJSON(localStorageTokenClaimsString);
        const localStorageReduxProfileUser =
          localStorageReduxProfileString && parseValidJSON(localStorageReduxProfileString);

        if (localStorageReduxProfileUser) {
          setReduxProfile({ ...localStorageReduxProfileUser });
          //todo: validate this JWT
          //setTokenClaims(localStorageTokenClaims);
        }
        // if (!localStorageTokenClaims && isAuthenticated === null) {
        //   setIsAuthenticated(false);
        // }
      }
      //Request up to date profile data from api
      if (tokenClaims?.__raw && !isReduxProfileSyncedWithApiRef.current) {
        getProfileData();
      }
    }, [tokenClaims?.__raw, getProfileData, reduxProfile, setReduxProfile]);

    //todo: currently only cartApi handles JWT on api side
    //so only cart api is able to provide 401 on expired JWT
    //on client side that's ONE way to handle expired JWT
    //but make sure to implement JWT expiration handling
    //upd: on auth0 loading we check if session is still authorized and invoke logoutExpiredSession if not
    const logoutExpiredJWT = (apiResponse) => {
      if (apiResponse.status === 401 && reduxProfile) logoutExpiredSession();
    };

    return (
      <Auth0Context.Provider
        value={{
          auth0Client,
          isAuthenticated,
          auth0User,
          signupRequired,
          setSignupAttemptCompleted,
          tokenClaims,
          getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
          loginWithRedirect: (...p) => loginWithRedirect(...p),
          getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
          getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
          logout: (...p) => logoutWithCleanSession(() => auth0Client?.logout(...p)),
          logoutWithRedirect: (...p) =>
            logoutWithCleanSession(() => auth0Client?.logout({ returnTo: redirect_uri, ...p })),
          logoutExpiredJWT,
          //use it to check if user profile will be loaded. profile is not present at page load, while this value is
          userAlreadyLoggedIn,
        }}
      >
        {children}
      </Auth0Context.Provider>
    );
  }
);
